import Vue from 'vue';
// import { v4 as uuid4 } from 'uuid';
import BrowserSerialPort from 'avrgirl-arduino/lib/browser-serialport';
import BaseSerial from './base-serial';

const { serial } = navigator;

// eslint-disable-next-line no-console
console.log('using navserial');
class NavSerial extends BaseSerial {
  constructor() {
    super();
    this.requestRequired = true;
    this.devices = JSON.parse(localStorage.portNames || '[]');
    this._currentDevice = null;
    this.implementation = 'navserial';
    this.handlesSelect = true;

    this._dataHandler = (buff) => {
      this._lastRead = buff;
      // eslint-disable-next-line no-console
      if (this.DEBUG) console.log('read', Buffer.from(buff).toString('hex'));
      if (!this.mute) this.emit('message', buff.toString(this.encoding));
      this.emit('data', buff);
    };
    this._openHandler = (...args) => {
      this.emit('open', ...args);
    };
    this._closeHandler = (...args) => {
      this.emit('close', ...args);
    };
    this._errorHandler = (...args) => {
      this.emit('close', ...args);
      this._currentDevice = null;
      Vue.set(this, 'currentDevice', null);

      if (this.serial) this._unregisterSerial();

      this.emit('currentDevice', null);
    };
    this._initSerial();
  }

  // eslint-disable-next-line class-methods-use-this
  async _getDevice(value) {
    // const devices = await serial.getPorts();
    // console.log(devices);
    // return devices.find((d) => d.id === value) || null;
    return value;
  }

  async requestDevice() {
    // Filter on devices.
    const filters = [
      // Arduino
      { usbVendorId: 0x2341, usbProductId: 0x0043 }, // UNO
      { usbVendorId: 0x2341, usbProductId: 0x0001 },
      { usbVendorId: 0x2341, usbProductId: 0x0243 },
      { usbVendorId: 0x2A03, usbProductId: 0x0043 },
      // WCH
      { usbVendorId: 0x1A86, usbProductId: 0x7523 }, // CH340
      { usbVendorId: 0x1A86, usbProductId: 0x5523 }, // CH341
      { usbVendorId: 0x4348, usbProductId: 0x5523 },
      // FTDI
      { usbVendorId: 0x0403, usbProductId: 0x6001 }, // FT232x & FT245x
      { usbVendorId: 0x0403, usbProductId: 0x6010 }, // FT2232
      { usbVendorId: 0x0403, usbProductId: 0x6011 }, // FT4232
      { usbVendorId: 0x0403, usbProductId: 0x6014 }, // FT232x
      { usbVendorId: 0x0403, usbProductId: 0x6015 }, // FTDI Bridge(I2C/SPI/UART/FIFO)
      { usbVendorId: 0x0403, usbProductId: 0x6031 }, // FTDI MISC
      // Bricked Counterfeit
      { usbVendorId: 0x0403, usbProductId: 0x0000 }, // FTDI FT232
      // Silicon Labs
      { usbVendorId: 0x10C4, usbProductId: 0x80A9 }, // CP210x
      { usbVendorId: 0x10C4, usbProductId: 0xEA60 },
      { usbVendorId: 0x10C4, usbProductId: 0xEA61 },
      { usbVendorId: 0x10C4, usbProductId: 0xEA70 },
      { usbVendorId: 0x10C4, usbProductId: 0xEA71 },
      { usbVendorId: 0x10C4, usbProductId: 0xEA80 },
      { usbVendorId: 0x10C4, usbProductId: 0xEAD0 },
      // Prolific
      { usbVendorId: 0x067B, usbProductId: 0x2303 }, // PL2303
      { usbVendorId: 0x067B, usbProductId: 0x2304 },
      { usbVendorId: 0x067B, usbProductId: 0x04BB },
      { usbVendorId: 0x067B, usbProductId: 0xAAA2 },
      { usbVendorId: 0x067B, usbProductId: 0xAAA3 },
      // Microchip
      { usbVendorId: 0x04D8, usbProductId: 0x000A }, // MCP2221
      { usbVendorId: 0x04D8, usbProductId: 0x0205 },
      { usbVendorId: 0x04D8, usbProductId: 0x0206 },
      { usbVendorId: 0x04D8, usbProductId: 0x00DF },
      { usbVendorId: 0x04D8, usbProductId: 0x0057 },
      { usbVendorId: 0x04D8, usbProductId: 0x006E },
      { usbVendorId: 0x04D8, usbProductId: 0x0208 },
      { usbVendorId: 0x04D8, usbProductId: 0x00DD },
      { usbVendorId: 0x04D8, usbProductId: 0x0207 },
      // Cypress
      { usbVendorId: 0x04B4, usbProductId: 0x0003 }, // CY7C65213
      // Holtek
      { usbVendorId: 0x04D9, usbProductId: 0xB534 }, // HT42B534
    ];
    try {
      const device = await serial.requestPort({ classCode: 2, filters });
      this.setCurrentDevice(device);
    } catch (e) {
      this.emit('serialPortCancel', e);
    }
    // if (!device.id) device.id = uuid4();
    // if (await this.getDeviceName(device.id)) {
    //   console.log(this.getDeviceName(device.id));
    //   this.setCurrentDevice(device.id);
    // } else {
    //   this.emit('deviceNamePrompt', device.id);
    // }
  }

  async isDevice(value) {
    return !!(await this._getDevice(value));
  }

  _registerSerial(port) {
    this.serial = new BrowserSerialPort(port, {
      baudRate: this.baud,
      autoOpen: false,
    });
    this.serial.on('data', this._dataHandler);
    this.serial.on('open', this._openHandler);
    this.serial.on('close', this._closeHandler);
    this.serial.on('error', this._errorHandler);
  }

  _unregisterSerial() {
    this.serial.off('data', this._dataHandler);
    this.serial.off('open', this._openHandler);
    this.serial.off('close', this._closeHandler);
  }

  async setCurrentDevice(value) {
    if (!(await this.isDevice(value))) return;
    if (this.connected) await this.disconnect();
    this._currentDevice = await this._getDevice(value);
    Vue.set(this, 'currentDevice', value);

    if (this.serial) this._unregisterSerial();
    this._registerSerial(value);

    this.emit('currentDevice', value);
    try {
      await this.connect();
      window.localStorage.lastNavSerialPort = JSON.stringify(value.getInfo());
    } catch (err) {
      if (err.message === 'Access denied.') {
        this.emit('errorPrompt', 'access_denied');
      }
      // eslint-disable-next-line no-console
      console.error(err);
      await this.disconnectDevice();
    }
    // eslint-disable-next-line no-console
    if (this.DEBUG) console.log(value, value.getInfo());
  }

  async disconnectDevice() {
    if (this.connected) await this.disconnect();
    this._currentDevice = null;
    Vue.set(this, 'currentDevice', null);

    if (this.serial) this._unregisterSerial();

    this.emit('currentDevice', null);
  }

  async _initSerial() {
    const { usbProductId, usbVendorId } = JSON.parse(window.localStorage.lastNavSerialPort || '{}');
    if (!usbVendorId || !usbProductId) return;
    const devices = await serial.getPorts();
    const device = devices.find((d) => {
      const info = d.getInfo();
      return usbProductId === info.usbProductId && usbVendorId === info.usbVendorId;
    });
    if (device) this.setCurrentDevice(device);
  }

  async setDeviceName(value, name) {
    if (!(await this.isDevice(value))) return;
    this.devices.push({ value, name });
    localStorage.portNames = JSON.stringify(this.devices);
    this.setCurrentDevice(value);
  }
}

export default NavSerial;
